@import "styles/fonts.css";
@import "styles/navigationStyles.css";

body {
  background: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.edb-header .tt-level1 {
  display: none !important;
}

.edb-header.edb-header-basic .tt-profile {
  display: none !important;
}

.tt-tt-profile__first-name {
  color: white;
}

.ebd-nav-bar-left-closed {
  transform: translateX(calc(-100% + 32px));
  transition: all 300ms linear;
  display: flex;
}

.ebd-nav-bar-left-open {
  transform: translateX(0%);
  transition: all 300ms linear;
  display: flex;
}

.ebd-navigation-button:hover {
  background: var(--tt-btn-bg);
  color: var(--tt-btn-color);
}

/*CSS Class to handle button activeness*/
.ebd-navigation-button-active {
  background: var(--bs-secondary);
  color: var(--bs-white);
}

.ebd-navigation-button:hover h6 {
  color: var(--bs-secondary);
}

.ebd-button-icon {
  width: 40px;
  height: 40px;
}

/*Toolbar*/
.space {
  flex-grow: 1;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 10px 0;
}

.toolbar > * {
  padding-left: 2px;
  padding-right: 2px;
}

.btn-secondary a {
  color: #fff;
}

.bluish-Theme {
  --tt-header-bg: linear-gradient(90deg, #4583a4, #342b60 60%);
  --tt-btn-bg: rgba(228, 6, 126, 0.1);
  --tt-btn-color: #342b60;
}

.ebd-toast-custom-styling {
  top: 4em;
}

.ebd-no-margin {
  margin-top: 5rem;
}
