.ebd-file-element {
  padding: 12px;
  background: rgba(241, 241, 241, 0.75);
  border: 1px #545454 dashed;
  border-radius: 4px;
  margin: 8px 0;
}

ul {
  padding: 0;
}

.ebd-drop-area {
  border-bottom: 1px solid #a8a8a8;
  margin-bottom: 12px;
  width: 100%;
  height: 100%;
}

.ebd-file-container {
  border: 1px black dashed;
  padding: 0 24px;
  transition: 200ms linear;
  width: 100%;
  height: 100%;
}

.ebd-file-container:hover {
  border: 1px #e320a5 dashed;
  background: rgba(227, 32, 165, 0.08);
}

.ebd-active-drag {
  border: 1px #c640cb dashed;
  padding: 0 24px;
  background: rgba(241, 241, 241, 0.75);
  width: 100%;
  height: 100%;
}
