/*body .p-datatable .p-datatable-tbody > tr:nth-child(even) {*/
/*  background-color: #F5F5F7;*/
/*}*/

.p-column-title {
  /* Body M/Medium */

  font-family: proxima-nova-regular, sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.006em;

  color: #383838;
}

td {
  line-height: 19px;
  color: var(--bs-primary);
}

td:last-child {
  width: 130px;
}

th:last-child {
  width: 130px;
}

.ebd-floating-td {
  position: sticky;
  right: 0;
  z-index: 100000000;
  background: repeating-radial-gradient(#ffffff, #ffffff 200px);
  cursor: pointer;
}

.ebd-table-mask {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  background: rgba(0, 0, 0, 0.27);
  z-index: 10000000000;
}

.table-element {
  display: block;
  width: 100%;
}

.row-element {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cell {
  display: inline-block;
  width: 25%;
}

.Parameter-header {
  font-family: proxima-nova-bold, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #342B60;
}

.ebd-initials-column {
  display: flex;
  max-width: 200px;
  flex-wrap: wrap;
}

.ebd-initials-first {
  min-width: 30px;
  background: #3f51b5;
}

.ebd-initials {
  margin-right: -15px;
}

.ebd-last-initial {
  margin-left: 15px;
  display: flex;
  align-items: center;
  position: relative;
}

.ebd-remaining-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  color: rgb(255, 255, 255);
  font-weight: bolder;
  width: 80%;
  height: 80%;
  background: rgba(51, 51, 51, 0.59);
  border-radius: 50%;
  cursor: pointer;
}


.w-10 {
  width: 10% !important;
}

.w-5 {
  width: 5% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}
