.output-window {
  margin: 10px;
  border: 1px solid #d6d8db;
  border-radius: 4px;
}

.output-window-header {
  display: flex;
  background-color: rgba(0,0,0,.26);
  background-color: #3f51b5;
  color: white;
  margin:0px;
  padding: 5px 20px 5px 5px;
}

.output-window-header > :first-child {
  flex-grow: 1;
  margin: 0px;
  font-weight: 500;
}

.output-window-clear {
  cursor: pointer;
}

.output-window-content {
  min-height: 400px;
  overflow: hidden scroll;
}

.output-window-content .empty-messages {
  text-align: center;
  padding-top: 50px;
}

.output-window-message-line {
  line-height: 24px;
  display: flex;
}

.output-window-message-line .mat-icon {
  cursor: pointer;
}

.output-window-message-expanded {
  padding-left: 100px;
}

.output-window-message-expanded pre {
  margin:0px;
}
