@import url(https://db.onlinewebfonts.com/c/0376a58122a881d16a294512d3c947b1?family=ProximaNova-Regular);

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.eot");
  src: url("https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.eot?#iefix") format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.woff2") format("woff2"),
  url("https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.woff") format("woff"),
  url("https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.ttf") format("truetype"),
  url("https://db.onlinewebfonts.com/t/0376a58122a881d16a294512d3c947b1.svg#ProximaNova-Regular") format("svg");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: proxima-nova-black, sans-serif;
}

h1 {
  font-size: 28px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

p {
  font-family: proxima-nova-regular, sans-serif;
  font-size: 16px;
}

h1.edb-heading-bold,
h2.edb-heading-bold,
h3.edb-heading-bold,
h4.edb-heading-bold,
h5.edb-heading-bold,
h6.edb-heading-bold,
p.edb-heading-bold {
  font-family: proxima-nova-bold, sans-serif;
}

h1.edb-heading-light,
h2.edb-heading-light,
h3.edb-heading-light,
h4.edb-heading-light,
h5.edb-heading-light,
h6.edb-heading-light,
p.edb-heading-light {
  font-family: proxima-nova-light, sans-serif;
}

.ebd-types {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  position: relative;
  font-weight: 700;
  line-height: 36px;
  color: rgba(228, 6, 126, 0.5);
  text-transform: capitalize;
  margin-bottom: 0;
}

.ebd-types-active {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  position: relative;
  font-weight: 700;
  line-height: 20px;
  color: rgb(228, 6, 126);
  text-transform: capitalize;
  margin-bottom: 0;
}

.ebd-types-active:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  z-index: 1000000;
  left: 0;
  background: rgba(228, 6, 126, 0.5);
}

.ebd-types:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  z-index: 1000000;
  left: 0;
  background: rgba(228, 6, 126, 0.5);
}

.ebd-parameters {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  line-height: 36px;
  font-weight: 700;
  color: rgba(52, 43, 96, 0.5);
  text-transform: capitalize;
  margin-bottom: 0;
}
