.tt-collapse-toggle__text {
  width: 100%;
}

.ebd-structure-input {
  min-width: 400px;
}

.ebd-material-input {
  max-width: 250px;
}

.ebd-thickness-input {
  width: 80px;
}

.ebd-remaining-width {
  width: 700px;
  height: 1px;
}

td {
  vertical-align: middle;
}

.ebd-building-components {
  background: var(--bs-light);
  padding: 8px 12px;
}

.ebd-delete:hover {
  color: rgba(255, 0, 0, 0.6);
  cursor: pointer;
}

.ebd-save:hover {
  color: rgba(43, 183, 39, 0.6);
  cursor: pointer;
}

.ebd-edit:hover {
  color: rgba(16, 26, 218, 0.6);
  cursor: pointer;
}

th {
  white-space: nowrap;
}

td {
  min-width: 150px;
}

.ebd-material-header {
  font-family: proxima-nova-regular, sans-serif;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: var(--bs-primary);
  margin-bottom: 0;
}
