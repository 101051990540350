h5 {
  text-transform: capitalize;
}

h6 {
  text-transform: capitalize;
  margin-left: 8px;
}

section {
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  padding: 12px;
  background: rgba(0, 0, 0, 0.009);
}

th {
  font-weight: 600;
  color: var(--bs-primary)
}
