.ebd-icon:hover {
  color: var(--bs-secondary);
}

.ebd-icon {
  display: inline-block;
  cursor: pointer;
  color: var(--bs-primary);
}

.ebd-icon svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}


.ebd-view svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
