.ebd-nav-bar-left-bg {
  background: #f5f5f7;
  min-height: calc(100vh - 2rem);
  padding: 0 12px 9px;
  margin-top: -6rem;
  width: 100%;
  max-width: 350px;
}

.ebd-button-up {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  background: var(--bs-white);
  color: var(--bs-primary-dark);
  transition: color, background-color 200ms linear;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  position: relative;
  top: -90px;
  z-index: 99;
}

.ebd-button-up:hover {
  background: var(--bs-secondary);
  color: var(--bs-white);
}

.ebd-nav-bar-left-closed {
  transform: translateX(calc(-100% + 32px));
  transition: all 300ms linear;
  display: flex;
}

.ebd-nav-bar-left-open {
  transform: translateX(0%);
  transition: all 300ms linear;
  display: flex;
  height: 100%;
}

.ebd-navigation-button {
  width: 100%;
  border-radius: 8px;
  font-weight: bolder;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 100%;
  margin-top: 13px;
  background: var(--bs-white);
  border: none;
  outline: none;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: var(--tt-btn-color);
  transition: color, background-color 200ms linear;
  padding: 8px 4px;
  white-space: break-spaces;
}

.ebd-navigation-button:hover {
  background: var(--bs-secondary);
  color: var(--bs-white);
}

/*CSS Class to handle button activeness*/
.ebd-navigation-button-active {
  background: var(--bs-secondary);
  color: var(--bs-white);
  width: 100%;
  border-radius: 8px;
  font-weight: bolder;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 0 100%;
  margin-top: 13px;
  border: none;
  outline: none;
  line-height: 25px;
  letter-spacing: 0.01em;
  transition: color, background-color 200ms linear;
  padding: 8px 4px;
  white-space: break-spaces;
}

.ebd-navigation-button-active h6 {
  color: var(--bs-white);
  transition: color 200ms linear;
}

.ebd-button-icon {
  width: 40px;
  height: 40px;
}

.ebd-row-closed {
  position: relative;
  left: calc(-16% + 45px);
  flex-wrap: nowrap;
  transition: all 200ms linear;
}

.ebd-row-open {
  left: 0;
  position: relative;
  transition: all 200ms linear;
}

.ebd-col-width-closed {
  position: relative;
  width: calc(100% - 100px) !important;
  left: 16%;
}

.ebd-col-width-open {
  position: relative;
  left: 16%;
}

.ebd-move-nav-bar {
  top: 123px;
  transition: top 150ms linear;
  position: fixed;
}

.ebd-stick-nav-bar {
  position: fixed;
  top: 150px;
  transition: top 150ms linear;
}

.ebd-hamburger-menu {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  height: 100vh;
  background: var(--bs-secondary-light);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: scroll;
}

.ebd-hamburger-element:hover {
  color: var(--bs-secondary);
}

.ebd-navigation-close {
  width: 32px;
  height: 32px;
  position: fixed;
  right: 30px;
  top: 90px;
  z-index: 101;
  cursor: pointer;
}

.ebd-navigation-close svg {
  width: 100%;
  height: 100%;
}
