.datatable-doc-demo .p-paginator .p-paginator-current {
  margin-left: auto;
}

.datatable-doc-demo .p-progressbar {
  height: 0.5rem;
  background-color: #d8dadc;
}

.datatable-doc-demo .p-progressbar .p-progressbar-value {
  background-color: #607d8b;
}

.datatable-doc-demo .p-datepicker {
  min-width: 25rem;
}

.datatable-doc-demo .p-datepicker td {
  font-weight: 400;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

.datatable-doc-demo,
.p-datatable.p-datatable-customers,
.p-datatable-thead,
> tr,
> th {
  text-align: left;
}

.datatable-doc-demo,
.p-datatable.p-datatable-customers,
.p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.p-paginator {
  justify-content: start;
  height: fit-content;
}

.p-paginator::before {
  margin-bottom: 12px;
  font-size: 12px;
}

.p-dropdown-label {
  padding: 0 0.75rem;
}

.p-dropdown {
  height: fit-content;
  border-radius: 0;
  border: 1px solid var(--bs-black);
}

.ebd-tag {
  background: var(--bs-white);
  padding: 8px 12px;
  border-radius: 16px;
  margin: 0 4px;
}

.p-datatable .p-datatable-header {
  background: #dadae4;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 0;
  min-width: 1.5rem;
}

.p-paginator .p-dropdown {
  height: 2rem;
}

.ebd-label-head {
  font-size: 0.75rem;
  font-weight: 100;
  color: var(--bs-gray-500);
}

.ebd-label-head-bold {
  font-size: 0.75rem;
  font-weight: 900;
  color: var(--bs-primary-dark);
}

.p-column-title {
  font-weight: 800;
}

.test-column-name {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.test-column-description {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
