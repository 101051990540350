.ebd-disabled-text {
  background: gray !important;
}

.ebd-label {
  font-family: proxima-nova-regular, sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-left: 9px;
}

.ebd-container-bg {
  background: rgba(245, 245, 247, 0.3);
  border: 1px solid #f5f5f7;
  border-radius: 4px;
}

.ebd-table-height {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(96, 125, 139, 0.07);
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.11);
}

.ebd-corridor-col {
  background: rgba(128, 128, 128, 0.11);
}

.ebd-no-model {
  margin-top: 30px;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(96, 125, 139, 0.07);
  width: 100%;
}

.ebd-model-image {
  width: 100%;
}

.ebd-shuffle-btn {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px 8px;
}

.ebd-full-width {
  width: 100%;
  height: 20px;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.ebd-configuration-table-height {
  max-height: 600px;
}

.ebd-table-row {
  transition: background-color 150ms linear;
  cursor: pointer;
}

.ebd-table-row:hover {
  background-color: var(--bs-secondary-light);
}

.ebd-component-elements-selected {
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

.ebd-component-elements {
  cursor: pointer;
}

td, th {
  /*white-space: nowrap;*/
}

#collapse-1 {
  overflow-x: scroll;
  height: 40vh;
  overflow-y: scroll;
}

#collapse-2 {
  overflow-x: scroll;
}

.ebd-blank-building-spaces {
  width: 100%;
  height: 40vh;
  background: rgba(128, 128, 128, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ebd-fit-content input, .ebd-fit-content select {
  width: fit-content;
}

.ebd-min-input-width {
  width: 100px;
}

.ebd-custom-btn {

}

.form-check {
  display: flex;
  align-items: center;
}

.form-check-label {
  margin-left: 8px;
  margin-bottom: 0;
}

.ebd-tabs-margin {
  margin-bottom: 16px;
}

.ebd-checkbox-label {
  font-size: 10px;
}

.overlay-div {
  width: 100%;
  height: 100%;
  background: #eaeaea;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 200ms;
  z-index: 1000;
  pointer-events: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 36px;
  font-weight: bolder;
}

.overlay-div:hover {
  opacity: 0.2;
}

.uploader {
  position: absolute;
  z-index: 5;
  top: 0;
  left: -20px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer
}

.uploader-wrapper:hover .overlay-div {
  opacity: 0.5;
}
